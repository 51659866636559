<template>
  <div class="h-100">
    <div v-if="unitsLoaded" :class="`omniview-map omniview-map-${mapId}`"></div>
    <div class="d-flex align-content-center flex-column text-center" v-else>
      <span>Loading...</span>
    </div>
  </div>
</template>

<script>
import { Map as OMS } from "@omnicloud/maps-sdk";
import store from "@/store";
import { SLOT_TYPES } from "@/config/layoutConfig";
import layoutMixin from "@/mixins/layoutMixin";
import { v4 as uuidv4 } from "uuid";
import LayoutApi from "@/libs/LayoutApi.js";
import { EventBus } from "@/libs/event-bus";
import useAppConfig from '@core/app-config/useAppConfig'

const layoutApi = new LayoutApi(store);

let token = window.localStorage.accessToken;

export default {
  mixins: [layoutMixin],
  props: {
    alarm: {
      type: Object,
    },
    unit_id: {
      type: String | Number,
    },
    mode: {
      type: String,
      default: "units",
    },
    data: {
      type: Object,
      default: null,
    },
    tabInfo: Object,
  },
  data() {
    return {
      mapSelector: null,
      SLOT_TYPES,
      mapId: uuidv4(),
      unitsLoaded: false,
      map: null,
    };
  },
  computed: {
    units() {
      return store.state.grid.units;
    },
    loadingUnitsStatus() {
      return store.state.grid.loadingUnits;
    },
  },
  async mounted() {
    if (!this.loadingUnitsStatus && !this.units.length) {
      // TODO: Check if this is necesary
      // await store.dispatch("grid/getUnitsByTenant");
      // this.unitsLoaded = true;
    } else if (this.units.length > 0) {
      this.$nextTick(() => this.initMap());
      this.unitsLoaded = true;
    }
  },
  beforeDestroy() {
    if (this.mapSelector) {
      this.mapSelector.removeEventListener("camera:click", this.onCameraClick);
      this.mapSelector.removeEventListener("plane:click", this.onClickPlane);
      this.mapSelector.removeEventListener("camera:click-float", this.onCameraClickFloat);
    }
  },
  watch: {
    units(value) {
      if (value.length > 0) {
        this.unitsLoaded = true;
        this.$nextTick(() => this.initMap());
      }
    },
  },
  methods: {
    initMap() {
      try {
        this.map = new OMS({
          token,
          apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
          mode: 'units'
        });

        const { skin } = useAppConfig()

        const selector = `.omniview-map-${this.mapId}`;
        const drawingData =
          this.data && this.data.drawingData ? this.data.drawingData : null;
        this.mapSelector = document.querySelector(selector);

        let latlng = this.data?.center || { lat: 19.39068, lng: -99.2836975 };

        this.map.setConfig({
          container: selector,
          latLng: latlng,
          gmapsKey: process.env.VUE_APP_GMAPS_KEY,
          triggerEvents: true,
          mode: this.mode,
          drawingData,
          zoom: this.data?.zoom || 5,
          darkMode: skin.value == "dark" ? true : false
        });

        this.map.onMapReady().then(() => {
          this.map.setMarkers(this.units);
          setTimeout(() => {
            this.setMapCenter(this.units);
          }, 1000);
        });

        EventBus.on(`toogle-dark-mode`, (params) => {
          if (params == 'dark') {
            this.map.setDarkMode();
          } else {
            this.map.setLightMode();
          }
        });

        if (this.mapSelector) {
          this.mapSelector.addEventListener("camera:click", this.onCameraClick);
          this.mapSelector.addEventListener(
            "camera:click-float",
            this.onCameraClickFloat
          );
          this.mapSelector.addEventListener("plane:click", this.onClickPlane);
          this.mapSelector.addEventListener("circle:updated", this.onCircleUpdated);
          this.mapSelector.addEventListener("circle:removed", this.onCircleRemoved);
          this.mapSelector.addEventListener("map:idle", this.onIdle);
        }
      } catch (error) {
        console.error("Map|SetConfig", error);
      }
    },
    setMapCenter(units) {
      const unit = units.find((u) => u.id == this.unit_id);
      if (unit) {
        this.map.setCenter(
          parseFloat(unit.coordinates.lat),
          parseFloat(unit.coordinates.lng),
          20
        );
      }
    },
    onCameraClick({ detail }) {
      const unit = this.units.find((u) => u.id === detail.unit_id);
      const camera = unit.cameras.find((c) => c.id === detail.camera_id);
      setTimeout(() =>
        this.setSlotData(this.SLOT_TYPES.CAM_LIVE, camera.name, { camera })
      );
    },
    onCameraClickFloat({ detail }) {
      const unit = this.units.find((u) => u.id === detail.unit_id);
      const camera = unit.cameras.find((c) => c.id === detail.camera_id);
      const uuid = uuidv4();
      this.$store.commit("grid/addFloatVideos", {
        id: camera.camera_id,
        type: camera.camera_type,
        uuid,
      });
    },
    onClickPlane({ detail }) {
      const unit = this.units.find((u) => u.id === detail.unit_id);
      setTimeout(() =>
        this.setSlotData(this.SLOT_TYPES.PLANE, `plane_${unit.id}`, {
          planes_url: unit.unit_planes,
        })
      );
    },
    onCircleUpdated(event) {
      this.$emit("circle-updated", { action: "updated", data: event.detail });
    },
    onCircleRemoved(event) {
      this.$emit("circle-updated", { action: "removed", data: null });
    },
    onIdle(event) {
      layoutApi.updateSlotData(this.tabInfo.pos, SLOT_TYPES.UNITS_MAP, {
        data: event.detail,
      });
      //TODO: Check how send the info only when close the view
      store.dispatch("grid/updateUserConfig");
    },
  },
  errorCaptured(err, vm, info) {
    console.log(err);
  },
};
</script>

<style lang="scss">
.dark-layout .gm-style .gm-style-iw-c {
  background-color: #282f46;
}
</style>

<style lang="scss" scoped>
.omniview-map {
  width: 100%;
  height: 100%;
}
</style>
